<template>
  <div class="body-1">
    <div v-if="loadedConfiguration != undefined">
      <v-card>
        <v-card-title>{{loadedConfiguration.configurationName}}</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-layout wrap justify-space-between>
                <v-flex md4>
                  <v-text-field
                    v-model="loadedConfiguration.configurationName"
                    label="Configuration Name"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field
                    v-model="loadedConfiguration.configurationId"
                    label="Configuration Id"
                    required
                  ></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field
                    v-model="loadedConfiguration.configurationDuration"
                    label="Alert Duration (seconds)"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <v-layout wrap justify-space-between>
                <v-flex md4>
                  <v-text-field v-model="loadedConfiguration.state" label="State" required></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field v-model="loadedConfiguration.county" label="County" required></v-text-field>
                </v-flex>
                <v-flex md4>
                  <v-text-field
                    v-model="loadedConfiguration.jursidiction"
                    label="Jursidiction"
                    required
                  ></v-text-field>
                </v-flex>
              </v-layout>
            </v-container>
          </v-form>
          <v-alert type="error" :value="errorSavingConfiguration != ''">{{errorSavingConfiguration}}</v-alert>
        </v-card-text>
        <v-card-actions>
          <v-btn color="primary" @click="save">Save</v-btn>
          <v-btn @click="reset" text>Reset Changes</v-btn>
          <v-btn @click="cancel" text>Cancel</v-btn>
        </v-card-actions>
      </v-card>
      <br />
      <v-card>
        <v-card-title>Incident Rules</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-data-table
                :headers="incidentHeaders"
                :items="loadedConfiguration.incidents"
                :hide-default-footer="true"
                class="elevation-1"
              >
                <template v-slot:item.agencyId="{ item }">
                  <v-edit-dialog :return-value.sync="item.agencyId">
                    {{ item.agencyId }}
                    <template v-slot:input>
                      <v-text-field v-model="item.agencyId" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.cities="{ item }">
                  <v-edit-dialog
                    @save="saveCommaEdit(item.cities)"
                    @open="openCommaEdit(item.cities)"
                  >
                    <v-chip
                      color="secondary"
                      text-color="white"
                      :small="true"
                      v-for="(c, index) in item.cities"
                      :key="index"
                    >{{c}}</v-chip>
                    <template v-slot:input>
                      <v-text-field v-model="commaBasedArrayString" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.zones="{ item}">
                  <v-edit-dialog
                    @save="saveCommaEdit(item.zones)"
                    @open="openCommaEdit(item.zones)"
                  >
                    <v-chip
                      color="secondary"
                      text-color="white"
                      :small="true"
                      v-for="(c, index) in item.zones"
                      :key="index"
                    >{{c}}</v-chip>
                    <template v-slot:input>
                      <v-text-field v-model="commaBasedArrayString" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.counties="{ item}">
                  <v-edit-dialog
                    @save="saveCommaEdit(item.counties)"
                    @open="openCommaEdit(item.counties)"
                  >
                    <v-chip
                      color="secondary"
                      text-color="white"
                      :small="true"
                      v-for="(c, index) in item.counties"
                      :key="index"
                    >{{c}}</v-chip>
                    <template v-slot:input>
                      <v-text-field v-model="commaBasedArrayString" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.resourceDepartments="{ item}">
                  <v-edit-dialog
                    @save="saveCommaEdit(item.resourceDepartments)"
                    @open="openCommaEdit(item.resourceDepartments)"
                  >
                    <v-chip
                      color="secondary"
                      text-color="white"
                      :small="true"
                      v-for="(c, index) in item.resourceDepartments"
                      :key="index"
                    >{{c}}</v-chip>
                    <template v-slot:input>
                      <v-text-field v-model="commaBasedArrayString" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.resources="{ item}">
                  <v-edit-dialog
                    @save="saveCommaEdit(item.resources)"
                    @open="openCommaEdit(item.resources)"
                  >
                    <v-chip
                      color="secondary"
                      text-color="white"
                      :small="true"
                      v-for="(c, index) in item.resources"
                      :key="index"
                    >{{c}}</v-chip>
                    <template v-slot:input>
                      <v-text-field v-model="commaBasedArrayString" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.actions="{item}">
                  <v-icon small @click="removeRow(loadedConfiguration.incidents, item)">mdi-delete</v-icon>
                </template>
                <template v-slot:no-data>
                  <td>No data found</td>
                </template>
              </v-data-table>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="blue" @click="addIncidentRule">Add Incident Rule</v-btn>
        </v-card-actions>
      </v-card>
      <br />
      <v-card>
        <v-card-title>Resources</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-data-table
                :headers="resourceHeaders"
                :items="loadedConfiguration.resources"
                :server-items-length="loadedConfiguration.resources.length"
                :hide-default-footer="true"
                class="elevation-1"
              >
                <template v-slot:item.resourceId="{ item}">
                  <v-edit-dialog :return-value.sync="item.resourceId">
                    {{ item.resourceId }}
                    <template v-slot:input>
                      <v-text-field v-model="item.resourceId" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.resourceAlias="{ item}">
                  <v-edit-dialog :return-value.sync="item.resourceAlias">
                    {{ item.resourceAlias }}
                    <template v-slot:input>
                      <v-text-field v-model="item.resourceAlias" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.departmentId="{ item}">
                  <v-edit-dialog :return-value.sync="item.departmentId">
                    {{ item.departmentId }}
                    <template v-slot:input>
                      <v-text-field v-model="item.departmentId" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.agencyId="{ item}">
                  <v-edit-dialog :return-value.sync="item.agencyId">
                    {{ item.agencyId }}
                    <template v-slot:input>
                      <v-text-field v-model="item.agencyId" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.divider="{ item}">
                  <v-edit-dialog>
                    {{ item.divider }}
                    <template v-slot:input>
                      <v-checkbox v-model="item.divider" label="Divider"></v-checkbox>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    v-if="loadedConfiguration.resources.indexOf(item) !== loadedConfiguration.resources.length - 1"
                    small
                    @click="rowDown(loadedConfiguration.resources, item)"
                  >mdi-arrow-down</v-icon>
                  <v-icon small @click="removeRow(loadedConfiguration.resources, item)">mdi-delete</v-icon>
                  <v-icon
                    v-if="loadedConfiguration.resources.indexOf(item) !== 0"
                    small
                    @click="rowUp(loadedConfiguration.resources, item)"
                  >mdi-arrow-up</v-icon>
                </template>

                <template v-slot:no-data>
                  <td>No data found</td>
                </template>
              </v-data-table>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="blue" @click="addResourceRule">Add Resource Rule</v-btn>
        </v-card-actions>
      </v-card>
      <br />
      <v-card>
        <v-card-title>Notifications</v-card-title>
        <v-card-text>
          <v-form ref="form">
            <v-container>
              <v-data-table
                :headers="notificationHeaders"
                :items="loadedConfiguration.notify"
                :server-items-length="loadedConfiguration.notify.length"
                :hide-default-footer="true"
                class="elevation-1"
              >
                <template v-slot:item.agencyId="{ item }">
                  <v-edit-dialog :return-value.sync="item.agencyId">
                    {{ item.agencyId }}
                    <template v-slot:input>
                      <v-text-field v-model="item.agencyId" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.resource="{ item }">
                  <v-edit-dialog :return-value.sync="item.resource">
                    {{ item.resource }}
                    <template v-slot:input>
                      <v-text-field v-model="item.resource" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.city="{ item }">
                  <v-edit-dialog :return-value.sync="item.city">
                    {{ item.city }}
                    <template v-slot:input>
                      <v-text-field v-model="item.city" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.county="{ item }">
                  <v-edit-dialog :return-value.sync="item.county">
                    {{ item.county }}
                    <template v-slot:input>
                      <v-text-field v-model="item.county" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.zone="{ item }">
                  <v-edit-dialog :return-value.sync="item.zone">
                    {{ item.zone }}
                    <template v-slot:input>
                      <v-text-field v-model="item.zone" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.resourceDepartment="{ item }">
                  <v-edit-dialog :return-value.sync="item.resourceDepartment">
                    {{ item.resourceDepartment }}
                    <template v-slot:input>
                      <v-text-field
                        v-model="item.resourceDepartment"
                        label="Edit"
                        single-line
                      ></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.resourceStatus="{ item }">
                  <v-edit-dialog :return-value.sync="item.resourceStatus">
                    {{ item.resourceStatus }}
                    <template v-slot:input>
                      <v-text-field v-model="item.resourceStatus" label="Edit" single-line></v-text-field>
                    </template>
                  </v-edit-dialog>
                </template>
                <template v-slot:item.actions="{ item }">
                  <v-icon
                    small
                    @click="removeRow(loadedConfiguration.notify, item)"
                  >mdi-delete</v-icon>
                </template>

                <template v-slot:no-data>
                  <td>No data found</td>
                </template>
              </v-data-table>
            </v-container>
          </v-form>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="blue" @click="addNotificationRule">Add Notification Rule</v-btn>
        </v-card-actions>
      </v-card>
    </div>
    <p v-else>Loading...</p>
  </div>
</template>
<script>
import { mapState, mapActions } from "vuex";

let incidentHeaders = [
  {
    text: "Agency Id",
    value: "agencyId"
  },
  {
    text: "Cities",
    value: "cities"
  },
  {
    text: "Zones",
    value: "zones"
  },
  {
    text: "Counties",
    value: "counties"
  },
  {
    text: "Resource Departments",
    value: "resourceDepartments"
  },
  {
    text: "Resources",
    value: "resources"
  },
  {
    text: "Actions",
    value: "actions"
  }
];
let resourceHeaders = [
  {
    text: "Resource Id",
    value: "resourceId"
  },
  {
    text: "Alias",
    value: "resourceAlias"
  },
  {
    text: "Department Id",
    value: "departmentId"
  },
  {
    text: "AgencyId",
    value: "agencyId"
  },
  {
    text: "Divider",
    value: "divider"
  },
  {
    text: "Actions",
    value: "actions"
  }
];
let notificationHeaders = [
  {
    text: "Agency Id",
    value: "agencyId"
  },
  {
    text: "Resource",
    value: "resource"
  },
  {
    text: "City",
    value: "city"
  },
  {
    text: "County",
    value: "county"
  },
  {
    text: "Zone",
    value: "zone"
  },
  {
    text: "Resource Department",
    value: "resourceDepartment"
  },
  {
    text: "Resource Status",
    value: "resourceStatus"
  },
  {
    text: "Actions",
    value: "actions"
  }
];

export default {
  data() {
    return {
      incidentHeaders: incidentHeaders,
      resourceHeaders: resourceHeaders,
      notificationHeaders: notificationHeaders,
      commaBasedArrayString: "",
      tempDividerCheckbox: false
    };
  },
  computed: mapState({
    loadedConfiguration: state => state.admin.loadedConfiguration,
    isSavingConfiguration: state => state.admin.isSavingConfiguration,
    errorSavingConfiguration: state => state.admin.errorSavingConfiguration,
    forceRedirect: state => state.admin.hasSavedConfigurationRedirect
  }),
  methods: {
    ...mapActions([
      "loadAdminConfiguration",
      "saveAdminConfiguration",
      "adminAddNewIncident",
      "adminAddNewResource",
      "adminAddNewNotification"
    ]),
    save() {
      this.saveAdminConfiguration({
        configId: this.$route.params.configId,
        loadedConfiguration: this.loadedConfiguration
      });
    },
    cancel() {
      this.$router.push({ name: "adminConfigurationList" });
    },
    addIncidentRule() {
      this.adminAddNewIncident();
    },
    addResourceRule() {
      this.adminAddNewResource();
    },
    addNotificationRule() {
      this.adminAddNewNotification();
    },
    saveCommaEdit(v) {
      var output = this.commaBasedArrayString.split(",");
      v.length = 0;
      output.forEach(element => {
        v.push(element.trim());
      });
    },
    openCommaEdit(inArray) {
      this.commaBasedArrayString = inArray.join(",");
    },
    removeRow(array, item) {
      const index = array.indexOf(item);
      array.splice(index, 1);
    },
    rowDown(array, item) {
      const index = array.indexOf(item);
      const newIndex = index + 1;

      if (newIndex >= array.length) {
        var k = newIndex - array.length + 1;
        while (k--) {
          newIndex.push(undefined);
        }
      }
      array.splice(newIndex, 0, array.splice(index, 1)[0]);
    },
    rowUp(array, item) {
      const index = array.indexOf(item);
      const newIndex = index - 1 < 0 ? 0 : index - 1;

      if (newIndex >= array.length) {
        var k = newIndex - array.length + 1;
        while (k--) {
          newIndex.push(undefined);
        }
      }
      array.splice(newIndex, 0, array.splice(index, 1)[0]);
    },
    reset() {
      this.loadAdminConfiguration({ configId: this.$route.params.configId });
    }
  },
  created() {
    this.loadAdminConfiguration({ configId: this.$route.params.configId });
  },
  watch: {
    forceRedirect: () => {
      this.$router.push({ name: "adminUserList" });
    }
  }
};
</script>

